
import { ErrorHandlereMixin, ValidateMixin } from '@/mixins';
import {
  Component,
  Mixins,
} from 'vue-property-decorator';
import { UserService } from '@/modules/users/services';
import PageTitle from '@/components/page-title/PageTitle.vue';
import Table from '@/components/global/table/Table.vue';
import { Users } from '../types';

const user_service = new UserService();
const errorHandlerMixin = new ErrorHandlereMixin();

@Component({
  components: {
    PageTitle,
    Table,
  },
})
export default class UserIndex extends Mixins(ErrorHandlereMixin, ValidateMixin) {
  loading = true;
  users: Users.User[] = [];

  cols = [
    {
      text: '',
      value: 'draggle',
      sortable: false,
    },
    {
      text: 'ID',
      value: 'id',
    },
    {
      text: 'Логин',
      value: 'login',
    },
    {
      text: 'Имя',
      value: 'name',
    },
    {
      text: 'Email',
      value: 'email',
    },
    {
      text: 'Роль',
      value: 'roles[0].name',
    },
    {
      text: 'Действие',
      value: 'actions',
      sortable: false,
    },
  ];

  mounted() {
    this.get_users();
  }

  async get_users() {
    await user_service.get_users()
      .then((data) => {
        this.users = data;
      }).catch((error) => {
        errorHandlerMixin.handleServerError(error);
      })
      .finally(() => { this.loading = false; });
  }

  async send_reset_link(user: Users.User) {
    await user_service.reset_password(user)
      .then(() => {
        this.show_success('Email на сброс пароля отправлен');
      })
      .catch((error) => {
        errorHandlerMixin.handleServerError(error);
      });
  }
}
