import { HttpService } from '@/services/http-service';
import { Users } from '@/modules/users/types';

import PasswordReset = Users.PasswordReset;

export class UserService extends HttpService {
  private readonly base_path = 'users';

  async get_users() {
    const { data } = await this.http_client.get(this.base_path);

    return data.data;
  }

  async reset_password(user: Users.User) {
    const { data } = await this.http_client.patch(`${this.base_path}/${user.id}/reset-password`);

    return data.data;
  }

  async set_password(params: PasswordReset) {
    const { data } = await this.http_client.patch(`${this.base_path}/recovery`, params);

    return data.data;
  }
}
